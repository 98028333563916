import * as routes from "Figured/Assets/Modules/routes";
import * as notify from "Figured/Assets/Modules/notify";
import http from "Figured/Assets/Modules/http";
import Base from "./Base.js";
import Vue from "vue";
import _ from "lodash";

/**
 * This is the base collection class which abstracts and allows the following:
 *     - Fetching data provided by a fetch route, automatically converting each
 *       object in that data to the collection's model type.
 *     - Basic operations like map, filter, sum, sort.
 *     - Save entire collection if a bulk save route is supported.
 */
class Collection extends Base {

    /**
     * Creates a new instance, called when using 'new'.
     */
    constructor(models, options) {
        super();

        // Binds all methods of this instance to itself. This might not actually
        // be necessary for everything but allows for intuitive use of `this`
        // inside functions of this collection.
        this.bindAll();

        // Defines an automatic collection id. This is primarily to distinguish
        // between instances of the same collection, or where two collections
        // have the same data but aren't the same instance.
        Object.defineProperty(this, "__uid", {
            value:        Collection.auto_id += 1,
            enumerable:   false,
            configurable: false,
            writable:     false,
        });

        options = _.defaults(options, {
            models:         [],     // Internal model store.
            listeners:      {},     // Event listeners.
            page:           false,
            last:           false,
            loading:        true,
            saving:         false,
            fatal:          false,
        });

        // Set options on this instance.
        _.each(options, (value, option) => {
            this[option] = value;
        });

        // Add all given models (if any) to this collection.
        this.add(_.values(models));
    }

    /**
     * @returns {Collection}
     */
    copy() {
        return new (this.constructor)(_.map(this.models, model => model.copy()));
    }

    /**
     * Convenient accessor to support collection.length
     */
    get length() {
        return this.size();
    }

    /**
     * This should return the constructor for this collection's model type.
     * Should override this whenever you create a collection class.
     */
    model() {
        throw "Model class not defined in collection";
    }

    /**
     * Returns a route configuration in the form {key: name}, where key may be
     * 'save', 'fetch', 'delete' or any other custom key, and the name is what
     * will be passed to router to generate the URL. See @getRoute
     */
    routes() {
        return {};
    }

    /**
     * Emits an event by name. All listeners attached to this event will be called
     * with this collection as the first argument and any args passed to this method.
     *
     * Listeners can return `false` to cut the process short.
     *
     * @return {boolean} whether or not all listeners were handled successfully.
     */
    emit(event, context = {}) {
        let listeners = _.get(this.listeners, event, []);
        let completed = true;

        // Add defaults to the context.
        context = _.merge({}, context, {collection: this});

        // Run through each listener. If any of them return false, stop the
        // iteration and mark that the event wasn't handled by all listeners.
        _.each(listeners, (listener) => {
            return (completed = listener(context)) === false;
        });

        return completed;
    }

    /**
     * Register an event listener.
     */
    on(event, listener) {
        this.listeners[event] = _.get(this.listeners, event, []);
        this.listeners[event].push(listener);
    }

    /**
     * Creates a route using this model's configured route names and the
     * utility package's route generator.
     */
    getRoute(key, parameters, queryParameters = {}) {
        let name = _.get(this.routes(), key);

        // Check that this model supports the requested route key.
        if (!name) {
            throw `Route key '${key}' not configured`;
        }

        // Merge with the default parameters.
        parameters = _.defaultsDeep({}, this.getDefaultParameters(), parameters);

        return routes.get(name, parameters, queryParameters);
    }

    /**
     * Converts this collection to a JSON string.
     */
    toJSON() {
        return this.models;
    }

    /**
     * Removes all models from this collection.
     */
    clear() {
        Vue.set(this, "models", []);
    }

    /**
     * Syncs all models in this collection.
     */
    sync() {
        _.each(this.models, _.method("sync"));
    }

    /**
     * Resets all models in this collection.
     */
    reset() {
        _.each(this.models, _.method("reset"));
    }

    /**
     * Returns the number of models in this collection.
     */
    size() {
        return _.get(this.models, "length", 0);
    }

    /**
     * Returns whether this collection is empty.
     */
    isEmpty() {
        return this.size() === 0;
    }

    /**
     * Returns the numeric index of a model in this collection, or -1 otherwise.
     */
    indexOf(model) {
        return _.findLastIndex(this.models, {__uid: model.__uid});
    }

    /**
     * Returns a reference to the first model that matches some given criteria.
     */
    find(where) {
        return _.find(this.models, where);
    }

    /**
     * Removes a given model from this collection. This could be a raw
     */
    remove(model) {
        if (! model) {
            return;
        }

        // Support using a predicate to remove all models it returns true for.
        // Alternatively support an object of values to filter by.
        if (_.isFunction(model) || _.isPlainObject(model)) {
            _.each(_.filter(this.models, model), this.remove.bind(this));
            return;
        }

        // Support removing multiple models at the same time if an array was
        // given. A model would otherwise always be an object so this is safe.
        if (_.isArray(model)) {
            _.each(model, this.remove.bind(this));
            return;
        }

        // This is also just to catch a potential bug. All models should have
        // an auto id so this would indicate an unexpected state.
        if (_.isUndefined(model.__uid)) {
            throw "Model does not have an automatic id - unexpected state";
        }

        // Remove this collection from the model's collection registry so that
        // it won't be added again unexpectedly when the model is saved again.
        model.unregisterCollection(this);

        // Remove the model from the internal store.
        this.models.splice(this.indexOf(model), 1);

        this.emit("remove", {model});
    }

    /**
     * Internal helper to a new collection around an array models.
     */
    _wrap(models) {
        return new (this.constructor)(models);
    }

    /**
     * Creates a new collection of the same type that contains only the models
     * for which the given predicate returns true for or matches by property.
     *
     * Important: Even though this returns a new collection, the references to
     *            each model are still the same.
     */
    filter(predicate) {
        return this._wrap(_.filter(this.models, predicate));
    }

    /**
     * Creates a new collection of the same type that contains the result of
     * applying a function to each model in this collection.
     *
     * Important: Even though this returns a new collection, the references to
     *            each model are still the same.
     */
    map(iteratee) {
        return this._wrap(_.map(this.models, iteratee));
    }

    /**
     * Returns the sum of all models, accessed by a field or callback.
     */
    sum(iteratee) {
        return _.sumBy(this.models, iteratee);
    }

    /**
     * Sorts this collection's models by a given criteria or comparator.
     */
    sort(iteratee) {
        Vue.set(this, "models", _.sortBy(this.models, iteratee));
    }

    /**
     * Create a new model of this collection's model type.
     */
    createModel(data) {
        return new (this.model())(data, this);
    }

    /**
     * Adds a model to this collection.
     */
    add(model) {

        if (!model) {
            return;
        }

        // If given an array, assume an array of models, and add them all.
        if (_.isArray(model)) {
            _.each(model, this.add.bind(this));
            return;
        }

        // Assume a single object, so add as model.
        if (_.isPlainObject(model)) {
            return this.add(this.createModel(model));
        }

        // Assume that we're adding a model.
        this.models.push(model);
        model.registerCollection(this);

        this.emit("add", {model});

        return model;
    }

    /**
     * Returns the first model of this collection if there is one.
     */
    first() {
        return _.first(this.models);
    }

    /**
     * Returns the last model of this collection if there is one.
     */
    last() {
        return _.last(this.models);
    }

    /**
     * Removes the first model of this collection if there is one.
     */
    shift() {
        this.remove(this.first());
    }

    /**
     * Removes the last model of this collection if there is one.
     */
    pop() {
        this.remove(this.last());
    }

    /**
     * Replaces all models in this collection with those provided.
     */
    replace(models) {
        this.clear();

        this.add(models);
        this.emit("replace", {models});
    }

    /**
     * Returns the parameters that should be used when building the fetch route.
     */
    getFetchParameters() {
        return {};
    }

    /**
     * Returns the query parameters that should be used when building the fetch route.
     */
    getFetchQueryParameters() {
        if (this.isPaginated()) {
            return this.page;
        }

        return {};
    }

    /**
     * Returns the parameters that should be used when building the save route.
     */
    getSaveParameters() {
        return {};
    }

    /**
     * Returns the parameters that should be used when building any route.
     */
    getDefaultParameters() {
        return {};
    }

    /**
     * Returns the data to use for saving.
     */
    getSaveData() {
        return this.models;
    }

    /**
     * Sets validation errors on each model in this collection.
     */
    setValidationErrors(errors) {
        _.each(errors, (errors, index) => {
            if ( ! _.isEmpty(errors)) {
                this.models[index].setErrors(errors);
            }
        });
    }

    /**
     * Handles saved data returned by the server.
     */
    onSave(data) {
        if (_.isEmpty(data)) {
            this.sync();
        } else {
            this.replace(data);
        }
    }

    /**
     * Saves all models in this collection.
     *
     * Usage:
     *
     * collection.save({
     *     success: () => {
     *         // Handle success here
     *     },
     *     failure: () => {
     *         // Handle failure here
     *     },
     *     always: () => {
     *         // Handle always here
     *     }
     * })
     *
     * OR
     *
     * collection.save(() => {
     *     // Handle success here
     * })
     *
     * @returns {Promise}
     */
    save(handlers = {}) {

        // Don't save if we're already busy saving this collection.
        // This prevents things like accidental double clicks.
        if (this.saving) {
            return;
        }

        // Abort if any of the save event listeners returned false.
        if ( ! this.emit("save")) {
            return;
        }

        // Parse the success and failure callbacks.
        this.parseResponseHandlers(handlers);

        let url  = this.getRoute("save");
        let data = this.getSaveData();

        // Clear errors.
        this.errors = {};

        // Start saving asynchronously, ie. non-blocking
        this.saving = true;

        return http
            .post(url, data).then((response) => {
                this.onSave(response.data);
                this.emit("save.success");
                handlers.success();

            }).catch((error) => {

                // Validation error, assume errors in JSON response.
                if (error.response.status === 422) {
                    this.setValidationErrors(error.response.data);
                }

                // Show error message when a request failed in a way that wasn't a
                // validation error, but still a user-error.
                if (error.response.status === 500) {
                    notify.error("An unexpected error has occurred.");
                }
                this.emit("save.fail");
                handlers.failure(error);

            }).finally(() => {

                // Even though the request may have failed, the model is not saving
                // anymore. The saving may have failed though.
                this.saving = false;
                this.emit("save.always");
                handlers.always();
            });
    }

    /**
     * Sets the page on this collection.
     *
     * Note: To disable pagination on this collection, pass page as `false`.
     */
    paginate(page = 1) {

        // If no arguments were passed, we're just trying to enable pagination.
        // We should default to page 1 if a page isn't already set.
        if (arguments.length === 0) {
            this.page = this.page || 1;

        // Page was provided, so we should either set the page or disable
        // pagination entirely if the page is `false`.
        } else {
            this.page = (page === false) ? false : _.toSafeInteger(page);
        }

        return this;
    }

    /**
     * Moves this collection to the next page.
     */
    nextPage() {
        this.page += 1;
    }

    /**
     * Returns whether this collection is currently being paginated.
     */
    isPaginated() {
        return _.isNumber(this.page) && this.page > 0;
    }

    /**
     * Returns whether this collection is on the last page, ie. there won't be
     * any more results that follow.
     */
    isLastPage() {
        return this.last;
    }

    /**
     * Called when a fetch request was successful.
     */
    onFetchSuccess(models) {

        // Automatically move on to the next page if we're paginating and
        // there were some results in the response.
        if (this.isPaginated()) {

            // The models returned by a paginated request reside in the 'data'
            // attribute, as there is pagination meta values in the response as well.
            if (_.has(models, "data")) {
                this.total = models.total;
                this.last_page = models.last_page;
                models = models.data;
            }

            if (!_.isArray(models)) {
                throw new Error("Models must be paginated properly!");
            }

            // If no models were returned in the response we can assume that
            // we're now on the last page, and we should not continue.
            if (_.isEmpty(models)) {
                this.last = true;

                // Otherwise, there were at least one model, and we can safely
                // assume that we want to increment the page number.
            } else {
                this.nextPage();
                this.add(models);
            }

            // Not paginating, so replace with the returned models, even if empty.
        } else {
            this.replace(models);
        }
    }

    /**
     * Fetches this collection's model data from the server.
     *
     * Usage:
     *
     * collection.fetch({
     *     success: () => {
     *         // Handle success here
     *     },
     *     failure: () => {
     *         // Handle failure here
     *     },
     *     always: () => {
     *         // Handle always here
     *     }
     * })
     *
     * OR
     *
     * collection.fetch(() => {
     *     // Handle success here
     * })
     */
    fetch(handlers = {}) {

        // Abort if any of the fetch event listeners returned false.
        if ( ! this.emit("fetch")) {
            return;
        }

        // Parse the success and failure callbacks.
        this.parseResponseHandlers(handlers);

        // Because we're fetching new data, we can assume that this collection
        // is now loading. This allows the template to indicate a loading state.
        this.loading   = true;
        this.fatal     = false;

        let parameters      = this.getFetchParameters();
        let queryParameters = this.getFetchQueryParameters();
        let fetchUrl        = this.getRoute("fetch", parameters, queryParameters);

        // Make an asynchronous POST request.
        return http
            .get(fetchUrl)
            .then((response) => {
                let models = response.data;
                this.onFetchSuccess(models);
                this.emit("fetch.success", {models});
                handlers.success(models);
            })

            // Called when the request failed.
            .catch((error) => {
                // Show error message when a request failed in a way that wasn't a
                // validation error, but still a user-error.
                if (error.response.status === 500) {
                    notify.error("An unexpected error has occurred.");
                }
                this.fatal = true;
                this.emit("fetch.fail");
                handlers.failure(error);
            })

            // Called when the request has completed, success or fail.
            .finally(() => {

                // Even though the request may have failed, the collection is not
                // loading anymore. `fatal` should be used to show an error state.
                this.loading = false;
                this.emit("fetch.always");
                handlers.always();
            });
    }
}

/**
 * Initialise a static collection ID.
 */
Collection.auto_id = 0;

export default Collection;
